'use client';

import { FC } from 'react';

import { Avatar, Box, Button, IconButton, Tooltip } from '@mui/material';

import { GOOGLE_AUTH_URL } from 'common/const/api.const';
import { IS_AUTH } from 'common/const/app.const';
import { useAuth } from 'common/hooks/useAuth';
import { getGlobalTranslate } from 'common/i18n/globalTranslate';
import { Locale } from 'common/i18n/i18n.config';
import { useLocale } from 'common/i18n/useLocale';
import { Editor } from 'common/routes';

import { SignOutButton } from './SignOutButton/SignOutButton';

interface Props {
  readonly lang: Locale;
}

export const AuthMenu: FC<Props> = async ({ lang }) => {
  const { locale } = useLocale();
  const dictionary = await getGlobalTranslate(locale);
  const { Actions, Navigation } = dictionary;

  const { isAuthenticated, jwt } = useAuth();

  const handleLogIn = () => {
    console.log('Log in', GOOGLE_AUTH_URL);
    window.location.href = GOOGLE_AUTH_URL;
  };

  const handleSignUp = () => {
    console.log('Sign up', GOOGLE_AUTH_URL);
    window.location.href = GOOGLE_AUTH_URL;
  };

  if (!IS_AUTH) {
    return null;
  }

  return isAuthenticated && jwt ? (
    <Box sx={{ alignItems: 'center', display: 'flex' }}>
      <Button color="primary" component={Editor.Link} lang={lang} sx={{ mr: 1 }} variant="contained">
        {Navigation.Editor}
      </Button>
      <Tooltip title={`${jwt.userProperties.firstName} ${jwt.userProperties.lastName} <${jwt.userProperties.email}>`}>
        <Box>
          <IconButton color="inherit" size="large" disabled>
            <Avatar src={jwt.userProperties.logo} />
          </IconButton>
          <SignOutButton />
        </Box>
      </Tooltip>
    </Box>
  ) : (
    <>
      <Button sx={{ mr: 1 }} variant="outlined" onClick={handleLogIn}>
        {Actions.LogIn}
      </Button>
      <Button color="primary" variant="contained" onClick={handleSignUp}>
        {Actions.SignUp}
      </Button>
    </>
  );
};
