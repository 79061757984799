'use client';

import { Box, Typography } from '@mui/material';
import { usePathname } from 'next/navigation';

import { getGlobalTranslate } from 'common/i18n/globalTranslate';
import { Locale } from 'common/i18n/i18n.config';
import { ToolsVideoCompressor, ToolsVideoConverter, ToolsVideoTrimmer } from 'common/routes';
import { BORDER, COLOR } from 'common/styles/colors';

import { Hero } from './Hero';
import { HeroProps } from './HeroProps';

export const HeroTools = async ({ lang, ...props }: HeroProps & { lang: Locale }) => {
  const pathname = usePathname();
  const { Labels, Navigation } = await getGlobalTranslate(lang);

  return (
    <Hero
      leftNode={
        <Box>
          <Typography component="h4" sx={{ mb: 4 }} variant="h5">
            {Labels.Tools}
          </Typography>

          <Typography sx={{ mt: 3 }} variant="body1">
            {Navigation.VideoTrimmer && (
              <ToolsVideoTrimmer.Link
                lang={lang}
                style={{
                  color: ToolsVideoTrimmer.isActive(lang, pathname) ? COLOR.PRIMARY_COLOR : 'inherit',
                  fontWeight: ToolsVideoTrimmer.isActive(lang, pathname) ? 'bold' : 'normal',
                  marginRight: 16
                }}
              >
                {Navigation.VideoTrimmer}
              </ToolsVideoTrimmer.Link>
            )}

            {Navigation.VideoCompressor && (
              <ToolsVideoCompressor.Link
                lang={lang}
                style={{
                  color: ToolsVideoCompressor.isActive(lang, pathname) ? COLOR.PRIMARY_COLOR : 'inherit',
                  fontWeight: ToolsVideoCompressor.isActive(lang, pathname) ? 'bold' : 'normal',
                  marginRight: 16
                }}
              >
                {Navigation.VideoCompressor}
              </ToolsVideoCompressor.Link>
            )}

            {Navigation.VideoConverter && (
              <ToolsVideoConverter.Link
                lang={lang}
                style={{
                  color: ToolsVideoConverter.isActive(lang, pathname) ? COLOR.PRIMARY_COLOR : 'inherit',
                  fontWeight: ToolsVideoConverter.isActive(lang, pathname) ? 'bold' : 'normal',
                  marginRight: 16
                }}
              >
                {Navigation.VideoConverter}
              </ToolsVideoConverter.Link>
            )}
          </Typography>
        </Box>
      }
      sx={{ borderTop: BORDER, pb: 7, pt: 7 }}
      {...props}
    />
  );
};
