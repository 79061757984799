import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Breadcrumbs/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Card/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/CardActionArea/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/CardContent/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/CardMedia/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Container/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Link/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Typography/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/components/buttons/AuthEditorButton/AuthEditorButton.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/components/Hero/HeroDiscoverTools/HeroDiscoverTools.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/components/Hero/HeroTools.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LogoSvg"] */ "/vercel/path0/src/common/components/Icons/LogoSvg.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocaleSwitcher"] */ "/vercel/path0/src/common/components/LocaleSwitcher/LocaleSwitcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocaleLink"] */ "/vercel/path0/src/common/components/OtherLocales/LocaleLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TopAppBar"] */ "/vercel/path0/src/common/components/TopAppBar/TopAppBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Editor"] */ "/vercel/path0/src/common/pages/EditorPage/components/Editor.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/HomePage/components/Hero1/hero.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/HomePage/components/HeroEditing/edit-videos.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/VideoCompressorPage/components/Hero1/video-compressor_block-1_hero_524x390.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/VideoCompressorPage/components/HeroHowToBlock/video-compressor_block-3_image-1_437x260.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/VideoCompressorPage/components/HeroHowToBlock/video-compressor_block-3_image-2_366x280.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/VideoCompressorPage/components/HeroHowToBlock/video-compressor_block-3_image-3_440x257.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/VideoConverterPage/components/Hero1/hero.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/VideoConverterPage/components/HeroHowToBlock/convert.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/VideoConverterPage/components/HeroHowToBlock/download.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/VideoConverterPage/components/HeroHowToBlock/upload.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/VideoTrimmerPage/components/Hero1/hero.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/VideoTrimmerPage/components/HeroHowToBlock/download.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/VideoTrimmerPage/components/HeroHowToBlock/trim.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/pages/VideoTrimmerPage/components/HeroHowToBlock/upload.webp");
