// eslint-disable-next-line no-restricted-imports
import pkg from '../../../package.json';

export const GOOGLE_TAG_MANAGER_ID = process.env.GOOGLE_TAG_MANAGER_ID;
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const IS_DEVELOPMENT = !IS_PRODUCTION;
export const SENTRY_DNS = process.env.SENTRY_DNS;
export const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;

export const APP_DOMAIN = IS_PRODUCTION ? 'videmix.com' : 'localhost:8082';
export const APP_URL = IS_PRODUCTION ? `https://www.${APP_DOMAIN}` : `https://${APP_DOMAIN}`;
export const APP_VERSION = pkg.version || '1.0.0';

export const IS_AUTH = process.env.NEXT_PUBLIC_IS_AUTH || true;
