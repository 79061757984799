import { VIDEO_FILE_UPLOAD_URL, VIDEO_ID_DOWNLOAD_URL } from 'common/const/api.const';

import { VideoFile } from '../pages/EditorPage/models/types';

export interface UploadedFile {
  link: string;
  videoId: string;
}

export const uploadVideoFile = async (videoFile: VideoFile, jwtToken: string): Promise<UploadedFile> => {
  const formData = new FormData();
  formData.append('file', videoFile.file);

  const response = await fetch(VIDEO_FILE_UPLOAD_URL, {
    headers: {
      Authorization: `Bearer ${jwtToken}`
    },
    method: 'POST'
  });

  const { link, videoId } = (await response.json()) as UploadedFile;

  console.log('response.data', { link, videoId });

  const uploadResponse = await fetch(link, {
    body: formData,
    method: 'PUT'
  });

  const data = await uploadResponse.json();

  console.log('upload.data', data);

  return { link, videoId };
};

export const getVideoIdFileUrl = async (videoId: string, jwtToken: string): Promise<UploadedFile> => {
  const response = await fetch(`${VIDEO_ID_DOWNLOAD_URL}${videoId}`, {
    headers: {
      Authorization: `Bearer ${jwtToken}`
    },
    method: 'GET'
  });

  return response.json();
};
